import React, { useEffect, useContext, useState } from "react";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteCurrentTask, setCurrentTask } from "../../redux/currentSlice";
import {
  addEstimate,
  addConfirm,
  addAssign,
  addChangeProgress,
  addComplete,
  addPay,
  addTask,
  deleteTask,
  removeUnread,
  addChangeOffer,
  submitRateTask,
  newMessage,
  addOthersUnread, updateTask, setTasks,
} from "../../redux/taskSlice";
import moment from "moment";
import { SocketContext } from "../../context/socket";
import api from "../../utils/axios";
import {
  API_URL,
  STATUS,
  ROLE,
  OFFER,
  ADMIN_NAME,
  DEADLINE,
  LANGUAGE,
} from "../../constants/constants";
import { toast } from "react-toastify";
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
} from "../../utils/localStorage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { changeBalance } from "../../redux/loginSlice";
import { useTranslation } from "react-i18next";
import { addMessage } from "../../redux/messageSlice";
import Archive from "../../assets/images/archive.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ProgressBar from "react-bootstrap/ProgressBar";
import { CProgress, CProgressBar } from "@coreui/react";
import { decodeEntities } from "../../utils/common";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import "animate.css";
import { Modal } from "react-bootstrap"
import Deposit from "../Billing/Deposit";
import SpecialOffer from "../../assets/images/special-offer.png"

const Task = React.memo(({ task, index, totalUserDue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = getUserFromLocalStorage();

  const { width, height } = useWindowSize();

  const socket = useContext(SocketContext);

  const tasks = useSelector((state) => state.task);
  const currentTask = useSelector((state) => state.current);

  const [taskId, setTaskId] = useState();
  const [taskname, setTaskName] = useState(task.taskname);
  const [duedate, setDuedate] = useState(task.duedate);
  const [deadline, setDeadline] = useState(task.deadline);
  const [developer, setDeveloper] = useState(
    task.developer
      ? task.developer
      : {
        username: "PE",
        avatar:
          "https://res.cloudinary.com/dbjfuowqq/image/upload/v1667245571/wp-sofa-avatar/avatar_kuuspt.png",
      }
  );
  const [percent, setPercent] = useState(task.percent);
  const [client, setClient] = useState(task.client);
  const [changeDeveloper, setChangeDeveloper] = useState(0);
  const [priority, setPriority] = useState(task.priority);
  const [createdtime, setCreatedtime] = useState(task.createdAt);
  const [status, setStatus] = useState(task.status);
  const [hourlyrate, setHourlyrate] = useState(task.hourlyrate | 0);
  const [fasthourlyrate, setFasthourlyrate] = useState(task.fasthourlyrate | 15);
  const [fastduedate, setFastduedate] = useState(task.fastduedate);
  const [workeffort, setWorkeffort] = useState(task.workeffort);
  const [selectedoffer, setSelectedoffer] = useState(task.selectedoffer);
  const [cost, setCost] = useState(task.cost);
  const [estimateShow, setEstimateShow] = useState(true);
  const [changeofferShow, setChangeofferShow] = useState(false);
  const [assignShow, setAssignShow] = useState(true);
  const [rating, setRating] = useState(task.rating);
  const [hoverRating, setHoverRating] = useState(0);
  const [duringdays, setDuringDays] = useState(task.duringdays);
  const [fastduringdays, setFastDuringDays] = useState(task.fastduringdays);

  // Change offer states
  const [hourlyrate_change, setHourlyrateChange] = useState(task.hourlyrate);
  const [workeffort_change, setWorkeffortChange] = useState(task.workeffort);
  const [duedate_change, setDuedateChange] = useState(task.duedate);
  const [fasthourlyrate_change, setFasthourlyrateChange] = useState(
    task.fasthourlyrate
  );
  const [fastduedate_change, setFastduedateChange] = useState(task.fastduedate);

  const [user, setUser] = useState({});
  const [unread, setUnread] = useState(0);

  const [devList, setDevList] = useState([]);
  const [comment, setComment] = useState(task.comment);

  const [isPriorityClick, setIsPriorityClicked] = useState(false);
  const [confettiStatus, setConfettiStatus] = useState(false);
  // const [isStatusClick, setIsStatusClicked] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const initialTime = 5 * 60 * 60; // 5 hours in seconds (18000 seconds)

  const [timeLeft, setTimeLeft] = useState(initialTime);

  const priorityList = ["Low", "Normal", "Urgent"];

  // const statusList = [
  //   t("to_estimate"),
  //   t("to_confirm"),
  //   t("to_do"),
  //   t("in_progress"),
  //   t("completed"),
  //   t("finished"),
  //   "",
  //   "",
  //   "",
  //   t("archived"),
  // ];

  useEffect(() => {
    // Set User
    const user = getUserFromLocalStorage();
    setUser(user);

    // Set Task states
    setTaskId(task._id);
    setDeadline(task.deadline);
    setDeveloper(
      task.developer
        ? task.developer
        : {
          username: "PE",
          avatar:
            "https://res.cloudinary.com/dbjfuowqq/image/upload/v1667245571/wp-sofa-avatar/avatar_kuuspt.png",
        }
    );
    setTaskName(task.taskname);
    setComment(task.comment);
    setPercent(task.percent);
    setClient(task.client);
    setPriority(task.priority);
    setCreatedtime(task.createdAt);
    setStatus(task.status);
    setWorkeffort(task.workeffort);
    setHourlyrate(task.hourlyrate | 0);
    setFasthourlyrate(task.fasthourlyrate | 15);
    setSelectedoffer(task.selectedoffer);
    setCost(task.cost);
    setWorkeffortChange(task.workeffort);
    setHourlyrateChange(task.hourlyrate);
    setFasthourlyrateChange(task.fasthourlyrate);
    setRating(task.rating);
    setDuringDays(task.duringdays);
    setFastDuringDays(task.fastduringdays);

    if (task.duringdays >= 0 && task.status < STATUS.CONFIRMED) {
      setDuedate(new Date().getTime() + 1000 * 3600 * 24 * task.duringdays);
      setDuedateChange(
        new Date().getTime() + 1000 * 3600 * 24 * task.duringdays
      );
    } else {
      setDuedate(new Date(task.duedate));
      setDuedateChange(new Date(task.duedate));
    }
    if (task.fastduringdays >= 0 && task.status < STATUS.CONFIRMED) {
      setFastduedate(
        new Date().getTime() + 1000 * 3600 * 24 * task.fastduringdays
      );
      setFastduedateChange(
        new Date().getTime() + 1000 * 3600 * 24 * task.fastduringdays
      );
    } else {
      setFastduedate(new Date(task.fastduedate));
      setFastduedateChange(new Date(task.fastduedate));
    }

    const allUnread = task.unread;
    const myUnread = allUnread.filter((item) => item.user == user._id);
    setUnread(myUnread[0].number);
  }, [task]);

  const onSelect = async () => {
    const updatedAtDate = moment(task.updatedTime).format("MM/DD/YYYY").toString();
    const currDate = moment(new Date()).format("MM/DD/YYYY").toString();
    if (task.status > 4 && updatedAtDate === currDate && user.role === 1) {
      setConfettiStatus(true);
      setTimeout(() => {
        setConfettiStatus(false);
      }, 5000);
    }
    if (task._id == currentTask._id) return;

    navigate("/chat?taskId=" + task._id);
    localStorage.setItem("taskId", task._id);
    dispatch(setCurrentTask(task));
    dispatch(removeUnread({ taskId: task._id, userId: user._id }));
    socket.emit("message-read", { task: task, userId: user._id });

    await api
      .post(`${API_URL}/api/v1/task/read`, {
        taskId: task._id,
      })
      .then((res) => {
        dispatch(removeUnread({ task: task, user: user }));
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onEstimate = async () => {
    const duringdays = getDays(duedate);
    const fastduringdays = getDays(fastduedate);

    await api
      .post(`${API_URL}/api/v1/task/estimate`, {
        taskId,
        workeffort,
        hourlyrate,
        duedate,
        fasthourlyrate,
        fastduedate,
        duringdays,
        fastduringdays,
      })
      .then(async (res) => {
        const data = res.data;

        dispatch(addEstimate(data));
        socket.emit("new-estimate", data);
        setEstimateShow(() => true);

        let message;
        if (client.translatelanguage) {
          await api
            .post(`${API_URL}/api/v1/message/translate/`, {
              message: `Hi ${data.client.username}, I estimated the task`,
              language: client.translatelanguage,
            })
            .then((res) => {
              message = decodeEntities(
                res.data.data.translations[0].translatedText
              );
            });
        } else {
          if (client.language == LANGUAGE.EN) {
            message = `Hi ${data.client.username}, I estimated the task`;
          } else if (client.language == LANGUAGE.DE)
            message = `Hallo ${data.client.username}, ich habe die Aufgabe geschätzt`;
          else if (client.language == LANGUAGE.ES)
            message = `Hi ${data.client.username}, I estimated the task`;
        }

        sendMessage(message);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => {
          toast.error(error.msg);
        });
      });
  };

  const onChangeOffer = async () => {
    const duringdays = getDays(duedate_change);
    const fastduringdays = getDays(fastduedate_change);

    await api
      .post(`${API_URL}/api/v1/task/changeoffer`, {
        taskId,
        workeffort: workeffort_change,
        hourlyrate: hourlyrate_change,
        duedate: duedate_change,
        fasthourlyrate: fasthourlyrate_change,
        fastduedate: fastduedate_change,
        duringdays,
        fastduringdays,
      })
      .then((res) => {
        setChangeofferShow(false);
        const data = res.data;
        dispatch(addChangeOffer(data));
        socket.emit("new-changeoffer", data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => {
          toast.error(error.msg);
        });
      });
  };

  const onChangePriority = async (priority) => { };

  const [costTasksConfirmed, setCostTasksConfirmed] = useState(0);

  useEffect(() => {
    const tasksConfirmed = tasks.filter((task) => task.status === STATUS.CONFIRMED)
    const totalCost = tasksConfirmed.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.cost;
    }, 0);
    setCostTasksConfirmed(totalCost)
  }, [tasks])

  const onConfirm = async () => {
    const user = getUserFromLocalStorage();

    const balance = user.balance - (totalUserDue || 0);
    const cost =
      selectedoffer == OFFER.NORMAL
        ? workeffort * hourlyrate
        : workeffort * fasthourlyrate;
    if (
      // (balance < cost && cost >= 5) && !JSON.parse(localStorage.getItem("BillingInfo"))
      (balance - (costTasksConfirmed + cost) < 0)
      // && !JSON.parse(localStorage.getItem("BillingInfo"))
    ) {
      setModalShow(true)
      toast.warning(
        `Hi ${user.username}, ${costTasksConfirmed === 0 ? "" : `there are ${user.currency === 0 ? '$' : '€'}${costTasksConfirmed} are reserved for confirmed tasks.`}. Please add money to your account (billing) to confirm that task...`
      );
    } else {
      const duedate = new Date().getTime() + 24 * 3600 * 1000 * duringdays;
      const fastduedate =
        new Date().getTime() + 24 * 3600 * 1000 * fastduringdays;
      await api
        .post(`${API_URL}/api/v1/task/confirm`, {
          taskId,
          selectedoffer,
          duedate,
          fastduedate,
          cost,
        })
        .then(async (res) => {
          const data = res.data;

          dispatch(addConfirm(data));
          socket.emit("new-confirm", data);

          let message;

          if (client.translatelanguage) {
            await api
              .post(`${API_URL}/api/v1/message/translate/`, {
                message:
                  hourlyrate * workeffort == 0
                    ? `Hi ${ADMIN_NAME}, I just confirmed the task Free`
                    : `Hi ${ADMIN_NAME}, I just confirmed the task`,
                language: client.translatelanguage,
              })
              .then((res) => {
                message = decodeEntities(
                  res.data.data.translations[0].translatedText
                );
              });
          } else {
            if (client.language == LANGUAGE.EN) {
              if (hourlyrate * workeffort == 0) {
                message = `Hi ${ADMIN_NAME}, I just confirmed the task Free`;
              } else {
                message = `Hi ${ADMIN_NAME}, I just confirmed the task`;
              }
            } else if (client.language == LANGUAGE.DE) {
              if (hourlyrate * workeffort == 0) {
                message = `Hallo ${ADMIN_NAME}, Ich habe gerade die Aufgabe Free bestätigt`;
              } else {
                message = `Hallo ${ADMIN_NAME}, ich habe gerade die Aufgabe bestätigt`;
              }
            } else if (client.language == LANGUAGE.ES) {
              if (hourlyrate * workeffort == 0) {
                message = `Hi ${ADMIN_NAME}, I just confirmed the task Free`;
              } else {
                message = `Hi ${ADMIN_NAME}, I just confirmed the task`;
              }
            }
          }

          sendMessage(message);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    }
  };

  const onAssign = async () => {
    await api
      .post(`${API_URL}/api/v1/task/assign`, {
        taskId,
        developer: devList[changeDeveloper]._id,
      })
      .then(async (res) => {
        const data = res.data;

        dispatch(addAssign({ task: data, role: user.role }));
        socket.emit("new-assign", data);

        let message;

        if (client.translatelanguage) {
          await api
            .post(`${API_URL}/api/v1/message/translate/`, {
              message: `Hi ${data.client.username}, I just moved the task into working.`,
              language: client.translatelanguage,
            })
            .then((res) => {
              message = decodeEntities(
                res.data.data.translations[0].translatedText
              );
            });
        } else {
          if (client.language == LANGUAGE.EN) {
            message = `Hi ${data.client.username}, I just moved the task into working.`;
          } else if (client.language == LANGUAGE.DE)
            message = `Hallo ${data.client.username}, ich habe die Aufgabe gerade in den Arbeitsmodus verschoben.`;
          else if (client.language == LANGUAGE.ES)
            message = `Hi ${data.client.username}, I just moved the task into working.`;
        }
        sendMessage(message);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onChangeProgress = async () => {
    await api
      .post(`${API_URL}/api/v1/task/changeprogress`, {
        taskId,
        percent: percent,
      })
      .then(async (res) => {
        const data = res.data;

        dispatch(addChangeProgress(data));
        socket.emit("new-changeprogress", data);

        let message;

        if (client.translatelanguage) {
          await api
            .post(`${API_URL}/api/v1/message/translate/`, {
              message: `Hi ${client.username}, I am on the task 😊, task has been done by ${percent}%`,
              language: client.translatelanguage,
            })
            .then((res) => {
              message = decodeEntities(
                res.data.data.translations[0].translatedText
              );
            });
        } else {
          if (client.language == LANGUAGE.EN) {
            message = `Hi ${client.username}, I am on the task 😊, task has been done by ${percent}%`;
          } else if (client.language == LANGUAGE.DE)
            message = `Hallo ${client.username}, I am on the task 😊, ich bin bei der Aufgabe 😊, Aufgabe wurde von ${percent}% erledigt`;
          else if (client.language == LANGUAGE.ES)
            message = `Hi ${client.username}, I am on the task 😊, task has been done by ${percent}%`;
        }

        sendMessage(message);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onComplete = async () => {
    await api
      .post(`${API_URL}/api/v1/task/complete`, {
        taskId,
      })
      .then(async (res) => {
        const data = res.data;

        dispatch(addComplete(data));
        socket.emit("new-complete", data);

        let message;

        if (client.translatelanguage) {
          await api
            .post(`${API_URL}/api/v1/message/translate/`, {
              message: `Hi ${data.client.username}, I just finished the task, can you please check?`,
              language: client.translatelanguage,
            })
            .then((res) => {
              message = decodeEntities(
                res.data.data.translations[0].translatedText
              );
            });
        } else {
          if (client.language == LANGUAGE.EN) {
            message = `Hi ${data.client.username}, I just finished the task, can you please check?`;
          } else if (client.language == LANGUAGE.DE)
            message = `Hallo ${data.client.username}, ich habe gerade die Aufgabe beendet, können Sie das bitte überprüfen?`;
          else if (client.language == LANGUAGE.ES)
            message = `Hi ${data.client.username}, I just finished the task, can you please check?`;
        }
        sendMessage(message);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onPay = async () => {
    const user = getUserFromLocalStorage();

    const balance = user.balance - (totalUserDue || 0);
    const cost =
      selectedoffer == OFFER.NORMAL
        ? workeffort * hourlyrate
        : workeffort * fasthourlyrate;
    if (
      // (balance - (costTasksConfirmed + cost) < 0) && !JSON.parse(localStorage.getItem("BillingInfo"))
      (balance < cost) && !JSON.parse(localStorage.getItem("BillingInfo"))
    ) {
      setModalShow(true)
      toast.warning(
        `Hi ${user.username}. Please add money to your account (billing) to pay this task...`
      );
      return;
    }
    await api
      .post(`${API_URL}/api/v1/task/pay`, {
        taskId,
        referralCode: user.giftCodeReceived
      })
      .then(async (res) => {
        const data = res.data;
        const user = getUserFromLocalStorage();
        const { cost } = data;
        dispatch(addPay(data));
        dispatch(changeBalance(-1 * cost));
        user.balance += -1 * cost;
        addUserToLocalStorage(user);
        socket.emit("new-pay", data);

        setConfettiStatus(true);
        setTimeout(() => {
          setConfettiStatus(false);
        }, 5000);

        let message;

        if (client.translatelanguage) {
          await api
            .post(`${API_URL}/api/v1/message/translate/`, {
              message: `Hi ${ADMIN_NAME}, I just paid for the task.`,
              language: client.translatelanguage,
            })
            .then((res) => {
              message = decodeEntities(
                res.data.data.translations[0].translatedText
              );
            });
        } else {
          if (client.language == LANGUAGE.EN) {
            message = `Hi ${ADMIN_NAME}, I just paid for the task.`;
          } else if (client.language == LANGUAGE.DE)
            message = `Hallo ${ADMIN_NAME}, ich habe gerade für die Aufgabe bezahlt.`;
          else if (client.language == LANGUAGE.ES)
            message = `Hi ${ADMIN_NAME}, I just paid for the task.`;
        }

        sendMessage(message);
        // socket.emit("handle-choose-maintain-service", { task: task, userId: user._id, type: "maintain_service--yes" })
        socket.emit("maintain-service", { task: task, userId: user._id, type: "maintain_service" })
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onArchived = async () => {
    await api
      .post(`${API_URL}/api/v1/task/archived`, {
        taskId,
      })
      .then((res) => {
        const task = res.data;
        socket.emit("archive-task", { task, userId: user._id });
        dispatch(submitRateTask(task));
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onDelete = async () => {
    await api.delete(`${API_URL}/api/v1/chatbot/${currentTask._id}`)
      .then((res) => { })
      .catch((err) => { })

    await api
      .post(`${API_URL}/api/v1/task/delete`, {
        taskId,
      })
      .then((res) => {
        const task = res.data;
        const taskId = task._id;
        dispatch(deleteCurrentTask());
        dispatch(deleteTask(taskId));
        socket.emit("delete-task", task);
        setEstimateShow(() => true);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onPaidDelete = async () => {
    await api
      .post(`${API_URL}/api/v1/task/paiddelete`, {
        taskId,
      })
      .then((res) => {
        const task = res.data;
        const taskId = task._id;
        dispatch(deleteCurrentTask());
        dispatch(deleteTask(taskId));
        socket.emit("delete-task", task);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onSubmitRating = async () => {
    if (rating == 0) {
      toast.warning("No stars are selected");
      return;
    }

    await api
      .post(`${API_URL}/api/v1/task/submitrating`, {
        taskId,
        rating,
        comment,
      })
      .then((res) => {
        const task = res.data;
        dispatch(submitRateTask(task));
        socket.emit("new-submitrate", task);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const getDays = (date) => {
    let difference = new Date(date).getTime() - new Date().getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };

  // Get developer list
  const getDeveloper = () => {
    const user = getUserFromLocalStorage();
    setUser(user);
    if (user.role === ROLE.ADMIN && task.status === STATUS.CONFIRMED)
      api
        .get(`${API_URL}/api/v1/user/dev`)
        .then((res) => {
          const data = res.data;
          setDevList([...data]);
        })
        .catch((err) => console.log(err));
  };

  const sendMessage = async (message) => {
    const user = getUserFromLocalStorage();

    await api
      .post(`${API_URL}/api/v1/message/send/`, {
        message: message,
        images: [],
        files: [],
        taskId: task._id,
      })
      .then((res) => {
        // dispatch(addMessage(res.data));
        dispatch(newMessage({ taskId: task._id, message: res.data.message }));

        dispatch(
          addOthersUnread({
            taskId: task._id,
            userId: user._id,
            senderId: user._id,
          })
        );

        // After store message, emit message sent event
        socket.emit("new-message", res.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }

        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onPriorityClick = () => setIsPriorityClicked(true);

  // const onStatusClick = () => setIsStatusClicked(true);

  const onSelectPriority = async (value) => {
    setIsPriorityClicked(false);
    await api
      .post(`${API_URL}/api/v1/task/updatepriority`, {
        priority: value,
        taskId: task._id,
      })
      .then((res) => {
        socket.emit("update-task", {
          task: { ...task, priority: value },
          userId: user._id,
        });
        dispatch(setCurrentTask({ ...task, priority: value }));
        dispatch(updateTask({ ...task, priority: value }));
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };
  // const onSelectStatus = async (value) => {
  //   setIsStatusClicked(false);
  //   await api
  //       .post(`${API_URL}/api/v1/task/updatestatus`, {
  //         status: status,
  //         taskId: task._id,
  //       })
  //       .then((res) => {
  //         socket.emit("update-task", {task: {...task, status: value }, userId: user._id});
  //         dispatch(setCurrentTask({...task, status: value }));
  //         dispatch(updateTask({...task, status: value}));
  //       })
  //       .catch((err) => {
  //         if (err.response.status == 401) {
  //           navigate("/");
  //         }
  //         const errors = err.response.data.errors;
  //         errors.forEach((error) => toast.error(error.msg));
  //       });
  // };

  const onMoveToProgress = async () => {
    await api
      .post(`${API_URL}/api/v1/task/movetoprogress`, {
        taskId: task._id,
      })
      .then((res) => {
        socket.emit("update-task", {
          task: { ...res.data },
          userId: user._id,
          isMoveInUpdate: true,
        });
        dispatch(setCurrentTask({ ...res.data }));
        dispatch(updateTask({ ...res.data }));
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const favouriteData = async (users) => {
    // debugger

    await api
      .post(`${API_URL}/api/v1/user/favourite`, {
        userId: users._id,
        isFavourite: users?.isFavourite ? false : true
      })
      .then((res) => {
        fetchData()
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const fetchData = async () => {
    await api
      .get(`${API_URL}/api/v1/task`)
      .then((res) => {
        dispatch(setTasks(res.data));
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }

        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  }

  // Function to format time
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  useEffect(() => {
    // Set up an interval that counts down every second
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0; // Stop at 0
        }
        return prevTime - 1; // Decrease time by 1 second
      });
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      {
        task.isHasBadge ?
          <div style={{ position: "relative" }}>
            <div style={{ position: "relative" }} className="task" onClick={onSelect}>
              {confettiStatus && (
                <Confetti width={width} height={height} recycle={confettiStatus} />
              )}
              <div
                className={`${currentTask._id == task._id ? "task-selected" : ""
                  } task-panel mt-3`}
              >
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div className="title">
                    {t("task")}: «{taskname}»
                  </div>

                  {user.role != ROLE.CLIENT &&
                    <div className="check-star cursor-pointer" onClick={() => favouriteData(task?.users[index])}>
                      <i className={`${task?.users[index]?.isFavourite ? " bi bi-star-fill " : " bi bi-star "} `}></i>
                    </div>}

                  <div className="d-flex align-items-center">
                    <div className="ms-3 me-2 client-name">
                      {client && client.username}
                    </div>
                    {status > STATUS.ESTIMATED ? (
                      <div className="check-mark">
                        <i className="bi bi-check-circle-fill"></i>
                      </div>
                    ) : (
                      <></>
                    )}
                    {unread &&
                      unread > 0 &&
                      (!task.latestMessage || task.latestMessage.sender != user._id) ? (
                      <div className="unread-messsages">
                        {t("new_messages")} ({unread}){" "}
                      </div>
                    ) : (
                      <></>
                    )}
                    {status === STATUS.PAID ||
                      status === STATUS.GETFEEDBACK ||
                      status === STATUS.COMPLETED ? (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">{t("archive_it")}</Tooltip>
                        }
                      >
                        <button className="delete-task-button" onClick={onArchived}>
                          <img src={Archive} alt={t("archive_it")} />
                        </button>
                      </OverlayTrigger>
                    ) : (
                      <></>
                    )}
                    {user.role == ROLE.CLIENT && status < STATUS.CONFIRMED ? (
                      <button className="delete-task-button" onClick={onDelete}>
                        <i className="bi bi-trash-fill"></i>
                      </button>
                    ) : (
                      <></>
                    )}
                    {user.role == ROLE.ADMIN &&
                      status != STATUS.PAID &&
                      status != STATUS.GETFEEDBACK ? (
                      <button className="delete-task-button" onClick={onDelete}>
                        <i className="bi bi-trash-fill"></i>
                      </button>
                    ) : (
                      <></>
                    )}
                    {user.role == ROLE.ADMIN &&
                      (status == STATUS.PAID || status == STATUS.GETFEEDBACK) ? (
                      <button className="delete-task-button" onClick={onPaidDelete}>
                        <i className="bi bi-trash-fill"></i>
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {user && user.isprojectenable && task.project && task.project.name && (
                  <span className="project-badge">
                    {(task.project && task.project.name) || ""}
                  </span>
                )}
                {/*{(user.role == ROLE.ADMIN || user.role == ROLE.DEV) &&*/}
                {/*<div className="d-flex mt-2 align-items-center">*/}
                {/*  <div className="sub-title">{t("status")}:</div>*/}
                {/*  {!isStatusClick ?*/}
                {/*      <div className="sub-content" onClick={onStatusClick}>*/}
                {/*        <button className="priority-button">{statusList[status]}</button>*/}
                {/*      </div> :*/}
                {/*      <select*/}
                {/*          className="priority-select"*/}
                {/*          value={status}*/}
                {/*          onChange={(e) => onSelectStatus(e.target.value)}*/}
                {/*      >*/}
                {/*        {statusList.map((item, index) => {*/}
                {/*          return (*/}
                {/*              item &&*/}
                {/*              <option key={index} value={index}>*/}
                {/*                {item}*/}
                {/*              </option>*/}
                {/*          );*/}
                {/*        })}*/}
                {/*      </select>*/}
                {/*  }*/}
                {/*</div>*/}
                {/*}*/}
                {status == STATUS.CREATED && user.role == ROLE.ADMIN ? (
                  estimateShow ? (
                    <>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("priority")}: </div>
                        {!isPriorityClick ? (
                          <div className="sub-content" onClick={onPriorityClick}>
                            <button className="priority-button">{priority}</button>
                          </div>
                        ) : (
                          <select
                            className="priority-select"
                            value={priority}
                            onChange={(e) => onSelectPriority(e.target.value)}
                          >
                            {priorityList.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                      {priority === "Urgent" && (
                        <div className="d-flex mt-2 align-items-center">
                          <div className="sub-title">{t("how_urgent")}: </div>
                          <div className="sub-content">
                            <button className="priority-button">
                              {deadline === DEADLINE.WITHIN_HOURS
                                ? "Within hours"
                                : deadline === DEADLINE.WITHIN_24HOURS
                                  ? "Within 24 hours"
                                  : "Within 48 hours"}
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="d-flex justify-content-end">
                        <button
                          className="estimate-button"
                          onClick={(e) => setEstimateShow(false)}
                        >
                          {t("estimate")}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("priority")}: </div>
                        {!isPriorityClick ? (
                          <div className="sub-content" onClick={onPriorityClick}>
                            <button className="priority-button">{priority}</button>
                          </div>
                        ) : (
                          <select
                            className="priority-select"
                            value={priority}
                            onChange={(e) => onSelectPriority(e.target.value)}
                          >
                            {priorityList.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>

                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("work_effort")}: </div>
                        <div className="sub-content">
                          <input
                            type="number"
                            value={workeffort}
                            className="workeffort-input"
                            onChange={(e) => {
                              const value = Math.max(0, e.target.value);
                              setWorkeffort(value);
                            }}
                          />{" "}
                          {t("hours")}
                        </div>
                      </div>
                      {/* Normal Offer */}
                      <div className="row mt-2">
                        <div className="col-4">
                          <hr />
                        </div>
                        <div className="col-4 text-center font-weight-bold">
                          {t("normal_offer")}
                        </div>
                        <div className="col-4">
                          <hr />
                        </div>
                      </div>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("hourly_rate")}: </div>
                        <div className="sub-content">
                          <input
                            type="number"
                            value={hourlyrate}
                            defaultValue={hourlyrate}
                            className="workeffort-input"
                            onChange={(e) => {
                              const value = Math.max(0, e.target.value);
                              setHourlyrate(value);
                            }}
                          />{" "}
                          {t("usd")}
                        </div>
                      </div>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          <DatePicker
                            selected={duedate}
                            minDate={moment().toDate()}
                            className="duedate-input"
                            onChange={(date) => setDuedate(date)}
                          />
                        </div>
                      </div>
                      {/* Fast Offer */}
                      <div className="row mt-2">
                        <div className="col-4">
                          <hr />
                        </div>
                        <div className="col-4 text-center">{t("fast_offer")}</div>
                        <div className="col-4">
                          <hr />
                        </div>
                      </div>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("hourly_rate")}: </div>
                        <div className="sub-content">
                          <input
                            type="number"
                            value={fasthourlyrate}
                            className="workeffort-input"
                            defaultValue={fasthourlyrate}
                            onChange={(e) => {
                              const value = Math.max(0, e.target.value);
                              setFasthourlyrate(value);
                            }}
                          />{" "}
                          {t("usd")}
                        </div>
                      </div>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          <DatePicker
                            selected={fastduedate}
                            minDate={moment().toDate()}
                            className="duedate-input"
                            onChange={(date) => setFastduedate(date)}
                          />
                        </div>
                      </div>
                      {/* Cancel and Complete Button */}
                      <div className="d-flex mt-2 justify-content-end align-items-center">
                        <button
                          className="estimate-cancel-button"
                          onClick={(e) => {
                            setEstimateShow(true);
                          }}
                        >
                          {t("cancel")}
                        </button>
                        <button
                          className="estimate-complete-button"
                          onClick={onEstimate}
                        >
                          {t("complete")}
                        </button>
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}
                {status == STATUS.CREATED && user.role == ROLE.CLIENT ? (
                  <>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("priority")}: </div>
                      {!isPriorityClick ? (
                        <div className="sub-content" onClick={onPriorityClick}>
                          <button className="priority-button">{priority}</button>
                        </div>
                      ) : (
                        <select
                          className="priority-select"
                          value={priority}
                          onChange={(e) => onSelectPriority(e.target.value)}
                        >
                          {priorityList.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      {moment(createdtime).fromNow()}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {/* write code here */}
                {status == STATUS.ESTIMATED && user.role == ROLE.CLIENT ? (
                  <>
                    {/* <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("priority")}: </div>
                      {!isPriorityClick ? (
                        <div className="sub-content" onClick={onPriorityClick}>
                          <button className="priority-button">{priority}</button>
                        </div>
                      ) : (
                        <select
                          className="priority-select"
                          value={priority}
                          onChange={(e) => onSelectPriority(e.target.value)}
                        >
                          {priorityList.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("work_effort")}: </div>
                      <div className="sub-content">
                        {workeffort} {t("hours")}
                      </div>
                    </div> */}

                    <div className="d-flex mt-2 mb-3 align-items-center justify-content-between">
                      <div className="sub-title">{t("valid_until")}: </div>
                      <div className="countdown-container">
                        <div className="timer-section">
                          <span className="timer">0{formatTime(timeLeft).split(":")[0]}</span>
                          <span className="label">hours</span>
                        </div>
                        <div className="timer-section">
                          <span className="timer">{formatTime(timeLeft).split(":")[1]}</span>
                          <span className="label">min</span>
                        </div>
                        <div className="timer-section">
                          <span className="timer">{formatTime(timeLeft).split(":")[2]}</span>
                          <span className="label">sec</span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 mb-4 justify-content-center">
                      <div
                        className={`col-5 absolute text-center ${selectedoffer == OFFER.NORMAL
                          ? "normal-offer-card-active"
                          : "normal-offer-card"
                          }`}
                        onClick={() => {
                          setSelectedoffer(OFFER.NORMAL);
                        }}
                      >
                        <div>{t("normal")}</div>
                        <div className="price">
                          {hourlyrate * workeffort == 0
                            ? "FREE"
                            : `$${hourlyrate * workeffort}`}
                        </div>
                        <div style={{ display: "flex", margin: "7px auto", justifyContent: "center" }}>
                          {
                            selectedoffer == OFFER.NORMAL ? <>
                              <p style={{ backgroundColor: "#E30607", color: "#FFF", fontWeight: "bold", padding: "5px", borderRadius: "5px" }}> -50%</p>
                              <p style={{ color: "#E30607", padding: "5px", borderRadius: "5px", textDecoration: "line-through" }}>100$</p>
                            </> : <>
                              <p style={{ backgroundColor: "#FFF", color: "#E30607", fontWeight: "bold", padding: "5px", borderRadius: "5px" }}> -50%</p>
                              <p style={{ color: "#FFF", padding: "5px", borderRadius: "5px", textDecoration: "line-through" }}>100$</p>
                            </>
                          }
                        </div>
                        <div
                          className={
                            selectedoffer == OFFER.NORMAL
                              ? "horizontal-normal-line-active"
                              : "horizontal-line"
                          }
                        ></div>
                        <div>
                          {t("due_date")}: {new Date(duedate).toLocaleDateString()}
                          <br />
                          +/- 3-5 days
                        </div>
                      </div>
                      <div className="col-1"></div>
                      <div
                        className={`col-5 text-center ${selectedoffer == OFFER.FAST
                          ? "fast-offer-card-active"
                          : "fast-offer-card"
                          }`}
                        onClick={() => {
                          setSelectedoffer(OFFER.FAST);
                        }}
                      >
                        <div>
                          <i className="bi bi-lightning-fill"></i> {t("fast")}
                        </div>
                        <div className="price">
                          {fasthourlyrate * workeffort == 0
                            ? "FREE"
                            : `$${fasthourlyrate * workeffort}`}
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", margin: "7px auto" }}>
                          {
                            selectedoffer === OFFER.NORMAL ? <>
                              <p style={{ backgroundColor: "#FFF", color: "#E30607", fontWeight: "bold", padding: "5px", borderRadius: "5px" }}> -50%</p>
                              <p style={{ color: "#FFF", padding: "5px", borderRadius: "5px", textDecoration: "line-through" }}>150$</p>
                            </> : <>
                              <p style={{ backgroundColor: "#E30607", color: "#FFF", fontWeight: "bold", padding: "5px", borderRadius: "5px" }}> -50%</p>
                              <p style={{ color: "#E30607", padding: "5px", borderRadius: "5px", textDecoration: "line-through" }}>150$</p>
                            </>
                          }
                        </div>
                        <div
                          className={
                            selectedoffer == OFFER.FAST
                              ? "horizontal-fast-line-active"
                              : "horizontal-line"
                          }
                        ></div>
                        <div>
                          {t("due_date")}: {new Date(fastduedate).toLocaleDateString()}
                          <br />
                          100% guarantee
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button className="estimate-button" onClick={onConfirm}>
                        {t("confirm")}
                      </button>
                    </div>



                  </>
                ) : (
                  <></>
                )}

                {status == STATUS.ESTIMATED && user.role == ROLE.ADMIN ? (
                  changeofferShow ? (
                    <>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("priority")}: </div>
                        {!isPriorityClick ? (
                          <div className="sub-content" onClick={onPriorityClick}>
                            <button className="priority-button">{priority}</button>
                          </div>
                        ) : (
                          <select
                            className="priority-select"
                            value={priority}
                            onChange={(e) => onSelectPriority(e.target.value)}
                          >
                            {priorityList.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("work_effort")}: </div>
                        <div className="sub-content">
                          <input
                            type="number"
                            value={workeffort_change}
                            className="workeffort-input"
                            onChange={(e) => {
                              const value = Math.max(1, e.target.value);
                              setWorkeffortChange(value);
                            }}
                          />{" "}
                          {t("hours")}
                        </div>
                      </div>
                      {/* Normal Offer */}
                      <div className="row mt-2">
                        <div className="col-4">
                          <hr />
                        </div>
                        <div className="col-4 text-center">{t("normal_offer")}</div>
                        <div className="col-4">
                          <hr />
                        </div>
                      </div>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("hourly_rate")}: </div>
                        <div className="sub-content">
                          <input
                            type="number"
                            value={hourlyrate_change}
                            className="workeffort-input"
                            onChange={(e) => {
                              const value = Math.max(1, e.target.value);
                              setHourlyrateChange(value);
                            }}
                          />{" "}
                          {t("usd")}
                        </div>
                      </div>

                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          <DatePicker
                            selected={duedate_change}
                            minDate={moment().toDate()}
                            className="duedate-input"
                            onChange={(date) => setDuedateChange(date)}
                          />
                        </div>
                      </div>
                      {/* Fast Offer */}
                      <div className="row mt-2">
                        <div className="col-4">
                          <hr />
                        </div>
                        <div className="col-4 text-center">{t("fast_offer")}</div>
                        <div className="col-4">
                          <hr />
                        </div>
                      </div>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("hourly_rate")}: </div>
                        <div className="sub-content">
                          <input
                            type="number"
                            value={fasthourlyrate_change}
                            className="workeffort-input"
                            onChange={(e) => {
                              const value = Math.max(1, e.target.value);
                              setFasthourlyrateChange(value);
                            }}
                          />{" "}
                          {t("usd")}
                        </div>
                      </div>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          <DatePicker
                            selected={fastduedate_change}
                            minDate={moment().toDate()}
                            className="duedate-input"
                            onChange={(date) => setFastduedateChange(date)}
                          />
                        </div>
                      </div>
                      {/* Cancel and Change Offer */}
                      <div className="d-flex mt-2 justify-content-end align-items-center">
                        <button
                          className="estimate-cancel-button"
                          onClick={(e) => {
                            setChangeofferShow(false);
                          }}
                        >
                          {t("cancel")}
                        </button>
                        <button
                          className="estimate-complete-button"
                          onClick={onChangeOffer}
                        >
                          {t("change")}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("priority")}: </div>
                        {!isPriorityClick ? (
                          <div className="sub-content" onClick={onPriorityClick}>
                            <button className="priority-button">{priority}</button>
                          </div>
                        ) : (
                          <select
                            className="priority-select"
                            value={priority}
                            onChange={(e) => onSelectPriority(e.target.value)}
                          >
                            {priorityList.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div> */}
                      {/* <div className="d-flex mt-2">
                        <div className="sub-title">{t("work_effort")}: </div>
                        <div className="sub-content">
                          {workeffort} {t("hours")}
                        </div>
                      </div> */}
                      <div className="d-flex mt-2 mb-3 align-items-center justify-content-between">
                        <div className="sub-title">{t("valid_until")}: </div>
                        <div className="countdown-container">
                          <div className="timer-section">
                            <span className="timer">0{formatTime(timeLeft).split(":")[0]}</span>
                            <span className="label">hours</span>
                          </div>
                          <div className="timer-section">
                            <span className="timer">{formatTime(timeLeft).split(":")[1]}</span>
                            <span className="label">min</span>
                          </div>
                          <div className="timer-section">
                            <span className="timer">{formatTime(timeLeft).split(":")[2]}</span>
                            <span className="label">sec</span>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2 mb-4 justify-content-center">
                        <div className="col-5 text-center normal-offer-card">
                          <div>{t("normal")}</div>
                          <div className="price">
                            {hourlyrate_change * workeffort == 0
                              ? "FREE"
                              : `$${hourlyrate_change * workeffort}`}
                          </div>
                          <div style={{ display: "flex", margin: "7px auto", justifyContent: "center" }}>
                            <p style={{ backgroundColor: "#FFF", color: "#E30607", fontWeight: "bold", padding: "5px", borderRadius: "5px" }}> -50%</p>
                            <p style={{ color: "#FFF", padding: "5px", borderRadius: "5px", textDecoration: "line-through" }}>100$</p>
                          </div>
                          <div className="horizontal-line"></div>
                          <div>
                            {t("due_date")}: {new Date(duedate).toLocaleDateString()}
                            <br />
                            +/- 3-5 days
                          </div>
                        </div>

                        <div className="col-1"></div>
                        <div className="col-5 text-center fast-offer-card">
                          <div>
                            <i className="bi bi-lightning-fill"></i> {t("fast")}
                          </div>
                          <div className="price">
                            {/* {fasthourlyrate * workeffort == 0
                        ? "FREE"
                        : `$${fasthourlyrate * workeffort}`} */}
                            {fasthourlyrate_change * workeffort == 0
                              ? "FREE"
                              : `$${fasthourlyrate_change * workeffort}`}
                          </div>
                          <div style={{ display: "flex", margin: "7px auto", justifyContent: "center" }}>
                            <p style={{ backgroundColor: "#FFF", color: "#E30607", fontWeight: "bold", padding: "5px", borderRadius: "5px" }}> -50%</p>
                            <p style={{ color: "#FFF", padding: "5px", borderRadius: "5px", textDecoration: "line-through" }}>150$</p>
                          </div>
                          <div className="horizontal-line"></div>
                          <div>
                            {t("due_date")}:{" "}
                            {new Date(fastduedate).toLocaleDateString()}
                            <br />
                            100% guarantee
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <button
                          className="estimate-button"
                          onClick={() => setChangeofferShow(true)}
                        >
                          {t("change_offer")}
                        </button>
                        <div>{t("waiting_for_confirmation")}</div>
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}

                {status == STATUS.CONFIRMED ? (
                  user.role == ROLE.CLIENT ? (
                    <>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("cost")}: </div>
                        <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                          })`}</div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          {selectedoffer == OFFER.NORMAL
                            ? new Date(duedate).toLocaleDateString()
                            : new Date(fastduedate).toLocaleDateString()}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("priority")}: </div>
                        {!isPriorityClick ? (
                          <div className="sub-content" onClick={onPriorityClick}>
                            <button className="priority-button">{priority}</button>
                          </div>
                        ) : (
                          <select
                            className="priority-select"
                            value={priority}
                            onChange={(e) => onSelectPriority(e.target.value)}
                          >
                            {priorityList.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("work_effort")}: </div>
                        <div className="sub-content">
                          {workeffort} {t("hours")}
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("mode")}: </div>
                        <div className="sub-content">{priority}</div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          {selectedoffer == OFFER.NORMAL
                            ? new Date(duedate).toLocaleDateString()
                            : new Date(fastduedate).toLocaleDateString()}
                        </div>
                      </div>
                      {assignShow ? (
                        <div className="d-flex justify-content-end">
                          <button
                            className="assign-button"
                            onClick={(e) => {
                              getDeveloper();
                              setAssignShow(false);
                            }}
                          >
                            {t("assign")}
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className="d-flex mt-2 align-items-center">
                            <div className="sub-title">{t("assign_to")}: </div>
                            <div className="sub-content">
                              <div>
                                <select
                                  className="priority-select"
                                  value={changeDeveloper}
                                  onChange={(e) => setChangeDeveloper(e.target.value)}
                                >
                                  {devList.map((item, index) => {
                                    return (
                                      <option key={index} value={index}>
                                        {item.username}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mt-2 justify-content-end align-items-center">
                            <button
                              className="estimate-cancel-button"
                              onClick={(e) => {
                                setAssignShow(true);
                              }}
                            >
                              {t("cancel")}
                            </button>
                            <button className="assign-button" onClick={onAssign}>
                              {t("complete")}
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )
                ) : (
                  <></>
                )}
                {status == STATUS.ASSIGNED ? (
                  user.role == ROLE.CLIENT ? (
                    <>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("cost")}: </div>
                        <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? t("hours") : t("hour")
                          })`}</div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          {selectedoffer == OFFER.NORMAL
                            ? new Date(duedate).toLocaleDateString()
                            : new Date(fastduedate).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("assigned")}:</div>
                        <div className="sub-content">{developer.username}</div>
                        <div>
                          <img
                            className="assigned-developer-avatar"
                            src={developer.avatar}
                          />
                          {/* <Avatar
                      className="assigned-developer-avatar"
                      name={developer.username}
                      round={true}
                      size="24px"
                    /> */}
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("in_progress")} </div>
                        <div className="sub-content d-flex align-items-center">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <input
                          className="w-75"
                          type="range"
                          id="rangeinput"
                          value={percent}
                          onChange={() => { }}
                        />
                        <span className="progress-percentage">{percent}%</span>
                      </div>
                    </>
                  ) : user.role == ROLE.DEV ? (
                    <>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("cost")}: </div>
                        <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? t("hours") : t("hour")
                          })`}</div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          {selectedoffer == OFFER.NORMAL
                            ? new Date(duedate).toLocaleDateString()
                            : new Date(fastduedate).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("assigned")}:</div>
                        <div className="sub-content">{developer.username}</div>
                        <div>
                          <img
                            className="assigned-developer-avatar"
                            src={developer.avatar}
                          />
                          {/* <Avatar
                      className="assigned-developer-avatar"
                      name={developer.username}
                      round={true}
                      size="24px"
                    /> */}
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("in_progress")} </div>
                        <div className="sub-content d-flex align-items-center">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <input
                          className="w-100"
                          type="range"
                          id="rangeinput"
                          value={percent}
                          onChange={(e) => setPercent(e.target.value)}
                        />
                      </div>

                      <div className="d-flex justify-content-between  mt-2">
                        <button className="estimate-button" onClick={onChangeProgress}>
                          {t("submit")}
                          {` (${percent}%)`}
                        </button>
                        <button
                          className="estimate-button"
                          onClick={(e) => onComplete(false)}
                        >
                          {t("confirm_complete")}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("cost")}: </div>
                        <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                          })`}</div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          {selectedoffer == OFFER.NORMAL
                            ? new Date(duedate).toLocaleDateString()
                            : new Date(fastduedate).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("assigned")}:</div>
                        <div className="sub-content">{developer.username}</div>
                        <div>
                          <img
                            className="assigned-developer-avatar"
                            src={developer.avatar}
                          />
                          {/* <Avatar
                      className="assigned-developer-avatar"
                      name={developer.username}
                      round={true}
                      size="24px"
                    /> */}
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("in_progress")} </div>
                        <div className="sub-content d-flex align-items-center">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <input
                          className="w-100"
                          type="range"
                          id="rangeinput"
                          value={percent}
                          onChange={(e) => setPercent(e.target.value)}
                        />
                      </div>

                      <div className="d-flex justify-content-between  mt-2">
                        <button className="estimate-button" onClick={onChangeProgress}>
                          {t("submit")}
                          {` (${percent}%)`}
                        </button>
                        <button
                          className="estimate-button"
                          onClick={(e) => onComplete(false)}
                        >
                          {t("confirm_complete")}
                        </button>
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}

                {status == STATUS.COMPLETED ? (
                  user.role == ROLE.CLIENT ? (
                    <>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("cost")}: </div>
                        <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                          })`}</div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          {selectedoffer == OFFER.NORMAL
                            ? new Date(duedate).toLocaleDateString()
                            : new Date(fastduedate).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("done")} </div>
                        <div className="sub-content d-flex align-items-center">
                          <div className="check-mark">
                            <i className="bi bi-check-circle-fill"></i>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <button className="estimate-button" onClick={(e) => onPay()}>
                          {t("confirm_pay")}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("cost")}: </div>
                        <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                          })`}</div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          {selectedoffer == OFFER.NORMAL
                            ? new Date(duedate).toLocaleDateString()
                            : new Date(fastduedate).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("done")} </div>
                        <div className="sub-content d-flex align-items-center">
                          <div className="check-mark">
                            <i className="bi bi-check-circle-fill"></i>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}
                {status == STATUS.PAID ? (
                  user.role == ROLE.CLIENT ? (
                    <>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          {selectedoffer == OFFER.NORMAL
                            ? new Date(duedate).toLocaleDateString()
                            : new Date(fastduedate).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("done_paid")}</div>
                        <div className="sub-content d-flex align-items-center">
                          <div className="check-mark">
                            <i className="bi bi-check-circle-fill"></i>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("feedback")} </div>
                        <div className="sub-content">
                          {[...Array(5)].map((star, index) => {
                            index += 1;
                            return (
                              <button
                                key={index}
                                className={
                                  index <= (hoverRating || rating)
                                    ? "star-button-on"
                                    : "star-button-off"
                                }
                                onClick={() => setRating(index)}
                                onMouseEnter={() => setHoverRating(index)}
                                onMouseLeave={() => setHoverRating(rating)}
                              >
                                <i className="bi bi-star-fill"></i>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                      <div className="d-flex mt-2 justidy-content-between">
                        <div className="sub-title">{t("comment")} </div>
                        <div className="sub-content w-100">
                          <textarea
                            value={comment}
                            className="w-100 p-2"
                            rows="3"
                            onChange={(e) => setComment(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <button className="estimate-button" onClick={onSubmitRating}>
                          {t("submit")}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("cost")}: </div>
                        <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                          })`}</div>
                      </div>
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("due_date")}: </div>
                        <div className="sub-content">
                          {selectedoffer == OFFER.NORMAL
                            ? new Date(duedate).toLocaleDateString()
                            : new Date(fastduedate).toLocaleDateString()}
                        </div>
                      </div>

                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("done_paid")}</div>
                        <div className="sub-content d-flex align-items-center">
                          <div className="check-mark">
                            <i className="bi bi-check-circle-fill"></i>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}

                {status == STATUS.GETFEEDBACK || status == STATUS.ARCHIVED ? (
                  <>
                    {status == STATUS.ARCHIVED && (
                      <div className="d-flex mt-2">
                        <div className="sub-title">{t("cost")}: </div>
                        <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                          })`}</div>
                      </div>
                    )}
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        {selectedoffer == OFFER.NORMAL
                          ? new Date(duedate).toLocaleDateString()
                          : new Date(fastduedate).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("done_paid")}</div>
                      <div className="sub-content d-flex align-items-center">
                        <div className="check-mark">
                          <i className="bi bi-check-circle-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("feedback")} </div>
                      <div className="sub-content">
                        {[...Array(5)].map((star, index) => {
                          index += 1;
                          return (
                            <button
                              key={index}
                              className={
                                index <= rating ? "star-button-on" : "star-button-off"
                              }
                            >
                              <i className="bi bi-star-fill"></i>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    {comment && (
                      <div className="d-flex mt-2 justidy-content-between">
                        <div className="sub-title">{t("comment")} </div>
                        <div className="sub-content w-100">{comment}</div>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {(user.role == ROLE.DEV || user.role == ROLE.ADMIN) &&
                  (task.status == STATUS.CREATED ||
                    task.status == STATUS.ESTIMATED ||
                    task.status == STATUS.CONFIRMED) && (
                    <div className="d-flex mt-2 justify-content-end">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            {t("free_task_tooltip")}
                          </Tooltip>
                        }
                      >
                        <button className="assign-button" onClick={onMoveToProgress}>
                          {t("move_to_progress")}
                        </button>
                      </OverlayTrigger>
                    </div>
                  )}
              </div>
              {
                <Modal
                  show={modalShow}
                  size="xl"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <div className="p-3">
                    <Deposit modalShow={modalShow} setModalShow={setModalShow} setConfettiStatus={setConfettiStatus} />
                  </div>
                </Modal>}
            </div>
            <div style={{ position: "absolute", top: "-15px", right: "0" }}>
              <img src={SpecialOffer} />
            </div>
          </div> :
          <div className="task" onClick={onSelect}>
            {confettiStatus && (
              <Confetti width={width} height={height} recycle={confettiStatus} />
            )}
            <div
              className={`${currentTask._id == task._id ? "task-selected" : ""
                } task-panel mt-3`}
            >
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="title">
                  {t("task")}: «{taskname}»
                </div>

                {user.role != ROLE.CLIENT &&
                  <div className="check-star cursor-pointer" onClick={() => favouriteData(task?.users[index])}>
                    <i className={`${task?.users[index]?.isFavourite ? " bi bi-star-fill " : " bi bi-star "} `}></i>
                  </div>}

                <div className="d-flex align-items-center">
                  <div className="ms-3 me-2 client-name">
                    {client && client.username}
                  </div>
                  {status > STATUS.ESTIMATED ? (
                    <div className="check-mark">
                      <i className="bi bi-check-circle-fill"></i>
                    </div>
                  ) : (
                    <></>
                  )}
                  {unread &&
                    unread > 0 &&
                    (!task.latestMessage || task.latestMessage.sender != user._id) ? (
                    <div className="unread-messsages">
                      {t("new_messages")} ({unread}){" "}
                    </div>
                  ) : (
                    <></>
                  )}
                  {status === STATUS.PAID ||
                    status === STATUS.GETFEEDBACK ||
                    status === STATUS.COMPLETED ? (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">{t("archive_it")}</Tooltip>
                      }
                    >
                      <button className="delete-task-button" onClick={onArchived}>
                        <img src={Archive} alt={t("archive_it")} />
                      </button>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                  {user.role == ROLE.CLIENT && status < STATUS.CONFIRMED ? (
                    <button className="delete-task-button" onClick={onDelete}>
                      <i className="bi bi-trash-fill"></i>
                    </button>
                  ) : (
                    <></>
                  )}
                  {user.role == ROLE.ADMIN &&
                    status != STATUS.PAID &&
                    status != STATUS.GETFEEDBACK ? (
                    <button className="delete-task-button" onClick={onDelete}>
                      <i className="bi bi-trash-fill"></i>
                    </button>
                  ) : (
                    <></>
                  )}
                  {user.role == ROLE.ADMIN &&
                    (status == STATUS.PAID || status == STATUS.GETFEEDBACK) ? (
                    <button className="delete-task-button" onClick={onPaidDelete}>
                      <i className="bi bi-trash-fill"></i>
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {user && user.isprojectenable && task.project && task.project.name && (
                <span className="project-badge">
                  {(task.project && task.project.name) || ""}
                </span>
              )}
              {/*{(user.role == ROLE.ADMIN || user.role == ROLE.DEV) &&*/}
              {/*<div className="d-flex mt-2 align-items-center">*/}
              {/*  <div className="sub-title">{t("status")}:</div>*/}
              {/*  {!isStatusClick ?*/}
              {/*      <div className="sub-content" onClick={onStatusClick}>*/}
              {/*        <button className="priority-button">{statusList[status]}</button>*/}
              {/*      </div> :*/}
              {/*      <select*/}
              {/*          className="priority-select"*/}
              {/*          value={status}*/}
              {/*          onChange={(e) => onSelectStatus(e.target.value)}*/}
              {/*      >*/}
              {/*        {statusList.map((item, index) => {*/}
              {/*          return (*/}
              {/*              item &&*/}
              {/*              <option key={index} value={index}>*/}
              {/*                {item}*/}
              {/*              </option>*/}
              {/*          );*/}
              {/*        })}*/}
              {/*      </select>*/}
              {/*  }*/}
              {/*</div>*/}
              {/*}*/}
              {status == STATUS.CREATED && user.role == ROLE.ADMIN ? (
                estimateShow ? (
                  <>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("priority")}: </div>
                      {!isPriorityClick ? (
                        <div className="sub-content" onClick={onPriorityClick}>
                          <button className="priority-button">{priority}</button>
                        </div>
                      ) : (
                        <select
                          className="priority-select"
                          value={priority}
                          onChange={(e) => onSelectPriority(e.target.value)}
                        >
                          {priorityList.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </div>
                    {priority === "Urgent" && (
                      <div className="d-flex mt-2 align-items-center">
                        <div className="sub-title">{t("how_urgent")}: </div>
                        <div className="sub-content">
                          <button className="priority-button">
                            {deadline === DEADLINE.WITHIN_HOURS
                              ? "Within hours"
                              : deadline === DEADLINE.WITHIN_24HOURS
                                ? "Within 24 hours"
                                : "Within 48 hours"}
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="d-flex justify-content-end">
                      <button
                        className="estimate-button"
                        onClick={(e) => setEstimateShow(false)}
                      >
                        {t("estimate")}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("priority")}: </div>
                      {!isPriorityClick ? (
                        <div className="sub-content" onClick={onPriorityClick}>
                          <button className="priority-button">{priority}</button>
                        </div>
                      ) : (
                        <select
                          className="priority-select"
                          value={priority}
                          onChange={(e) => onSelectPriority(e.target.value)}
                        >
                          {priorityList.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </div>

                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("work_effort")}: </div>
                      <div className="sub-content">
                        <input
                          type="number"
                          value={workeffort}
                          className="workeffort-input"
                          onChange={(e) => {
                            const value = Math.max(0, e.target.value);
                            setWorkeffort(value);
                          }}
                        />{" "}
                        {t("hours")}
                      </div>
                    </div>
                    {/* Normal Offer */}
                    <div className="row mt-2">
                      <div className="col-4">
                        <hr />
                      </div>
                      <div className="col-4 text-center font-weight-bold">
                        {t("normal_offer")}
                      </div>
                      <div className="col-4">
                        <hr />
                      </div>
                    </div>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("hourly_rate")}: </div>
                      <div className="sub-content">
                        <input
                          type="number"
                          value={hourlyrate}
                          defaultValue={hourlyrate}
                          className="workeffort-input"
                          onChange={(e) => {
                            const value = Math.max(0, e.target.value);
                            setHourlyrate(value);
                          }}
                        />{" "}
                        {t("usd")}
                      </div>
                    </div>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        <DatePicker
                          selected={duedate}
                          minDate={moment().toDate()}
                          className="duedate-input"
                          onChange={(date) => setDuedate(date)}
                        />
                      </div>
                    </div>
                    {/* Fast Offer */}
                    <div className="row mt-2">
                      <div className="col-4">
                        <hr />
                      </div>
                      <div className="col-4 text-center">{t("fast_offer")}</div>
                      <div className="col-4">
                        <hr />
                      </div>
                    </div>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("hourly_rate")}: </div>
                      <div className="sub-content">
                        <input
                          type="number"
                          value={fasthourlyrate}
                          className="workeffort-input"
                          defaultValue={fasthourlyrate}
                          onChange={(e) => {
                            const value = Math.max(0, e.target.value);
                            setFasthourlyrate(value);
                          }}
                        />{" "}
                        {t("usd")}
                      </div>
                    </div>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        <DatePicker
                          selected={fastduedate}
                          minDate={moment().toDate()}
                          className="duedate-input"
                          onChange={(date) => setFastduedate(date)}
                        />
                      </div>
                    </div>
                    {/* Cancel and Complete Button */}
                    <div className="d-flex mt-2 justify-content-end align-items-center">
                      <button
                        className="estimate-cancel-button"
                        onClick={(e) => {
                          setEstimateShow(true);
                        }}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        className="estimate-complete-button"
                        onClick={onEstimate}
                      >
                        {t("complete")}
                      </button>
                    </div>
                  </>
                )
              ) : (
                <></>
              )}
              {status == STATUS.CREATED && user.role == ROLE.CLIENT ? (
                <>
                  <div className="d-flex mt-2 align-items-center">
                    <div className="sub-title">{t("priority")}: </div>
                    {!isPriorityClick ? (
                      <div className="sub-content" onClick={onPriorityClick}>
                        <button className="priority-button">{priority}</button>
                      </div>
                    ) : (
                      <select
                        className="priority-select"
                        value={priority}
                        onChange={(e) => onSelectPriority(e.target.value)}
                      >
                        {priorityList.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </div>
                  <div className="d-flex justify-content-end">
                    {moment(createdtime).fromNow()}
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* write code here */}
              {status == STATUS.ESTIMATED && user.role == ROLE.CLIENT ? (
                <>
                  <div className="d-flex mt-2 align-items-center">
                    <div className="sub-title">{t("priority")}: </div>
                    {!isPriorityClick ? (
                      <div className="sub-content" onClick={onPriorityClick}>
                        <button className="priority-button">{priority}</button>
                      </div>
                    ) : (
                      <select
                        className="priority-select"
                        value={priority}
                        onChange={(e) => onSelectPriority(e.target.value)}
                      >
                        {priorityList.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </div>
                  <div className="d-flex mt-2">
                    <div className="sub-title">{t("work_effort")}: </div>
                    <div className="sub-content">
                      {workeffort} {t("hours")}
                    </div>
                  </div>
                  <div className="row mt-2 mb-4 justify-content-center">
                    <div
                      className={`col-5 text-center ${selectedoffer == OFFER.NORMAL
                        ? "normal-offer-card-active"
                        : "normal-offer-card"
                        }`}
                      onClick={() => {
                        setSelectedoffer(OFFER.NORMAL);
                      }}
                    >
                      <div>{t("normal")}</div>
                      <div className="price">
                        {hourlyrate * workeffort == 0
                          ? "FREE"
                          : `$${hourlyrate * workeffort}`}
                      </div>
                      <div
                        className={
                          selectedoffer == OFFER.NORMAL
                            ? "horizontal-normal-line-active"
                            : "horizontal-line"
                        }
                      ></div>
                      <div>
                        {t("due_date")}: {new Date(duedate).toLocaleDateString()}
                        <br />
                        +/- 3-5 days
                      </div>
                    </div>
                    <div className="col-1"></div>
                    <div
                      className={`col-5 text-center ${selectedoffer == OFFER.FAST
                        ? "fast-offer-card-active"
                        : "fast-offer-card"
                        }`}
                      onClick={() => {
                        setSelectedoffer(OFFER.FAST);
                      }}
                    >
                      <div>
                        <i className="bi bi-lightning-fill"></i> {t("fast")}
                      </div>
                      <div className="price">
                        {fasthourlyrate * workeffort == 0
                          ? "FREE"
                          : `$${fasthourlyrate * workeffort}`}
                      </div>
                      <div
                        className={
                          selectedoffer == OFFER.FAST
                            ? "horizontal-fast-line-active"
                            : "horizontal-line"
                        }
                      ></div>
                      <div>
                        {t("due_date")}: {new Date(fastduedate).toLocaleDateString()}
                        <br />
                        100% guarantee
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button className="estimate-button" onClick={onConfirm}>
                      {t("confirm")}
                    </button>
                  </div>



                </>
              ) : (
                <></>
              )}

              {status == STATUS.ESTIMATED && user.role == ROLE.ADMIN ? (
                changeofferShow ? (
                  <>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("priority")}: </div>
                      {!isPriorityClick ? (
                        <div className="sub-content" onClick={onPriorityClick}>
                          <button className="priority-button">{priority}</button>
                        </div>
                      ) : (
                        <select
                          className="priority-select"
                          value={priority}
                          onChange={(e) => onSelectPriority(e.target.value)}
                        >
                          {priorityList.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </div>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("work_effort")}: </div>
                      <div className="sub-content">
                        <input
                          type="number"
                          value={workeffort_change}
                          className="workeffort-input"
                          onChange={(e) => {
                            const value = Math.max(1, e.target.value);
                            setWorkeffortChange(value);
                          }}
                        />{" "}
                        {t("hours")}
                      </div>
                    </div>
                    {/* Normal Offer */}
                    <div className="row mt-2">
                      <div className="col-4">
                        <hr />
                      </div>
                      <div className="col-4 text-center">{t("normal_offer")}</div>
                      <div className="col-4">
                        <hr />
                      </div>
                    </div>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("hourly_rate")}: </div>
                      <div className="sub-content">
                        <input
                          type="number"
                          value={hourlyrate_change}
                          className="workeffort-input"
                          onChange={(e) => {
                            const value = Math.max(1, e.target.value);
                            setHourlyrateChange(value);
                          }}
                        />{" "}
                        {t("usd")}
                      </div>
                    </div>

                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        <DatePicker
                          selected={duedate_change}
                          minDate={moment().toDate()}
                          className="duedate-input"
                          onChange={(date) => setDuedateChange(date)}
                        />
                      </div>
                    </div>
                    {/* Fast Offer */}
                    <div className="row mt-2">
                      <div className="col-4">
                        <hr />
                      </div>
                      <div className="col-4 text-center">{t("fast_offer")}</div>
                      <div className="col-4">
                        <hr />
                      </div>
                    </div>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("hourly_rate")}: </div>
                      <div className="sub-content">
                        <input
                          type="number"
                          value={fasthourlyrate_change}
                          className="workeffort-input"
                          onChange={(e) => {
                            const value = Math.max(1, e.target.value);
                            setFasthourlyrateChange(value);
                          }}
                        />{" "}
                        {t("usd")}
                      </div>
                    </div>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        <DatePicker
                          selected={fastduedate_change}
                          minDate={moment().toDate()}
                          className="duedate-input"
                          onChange={(date) => setFastduedateChange(date)}
                        />
                      </div>
                    </div>
                    {/* Cancel and Change Offer */}
                    <div className="d-flex mt-2 justify-content-end align-items-center">
                      <button
                        className="estimate-cancel-button"
                        onClick={(e) => {
                          setChangeofferShow(false);
                        }}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        className="estimate-complete-button"
                        onClick={onChangeOffer}
                      >
                        {t("change")}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("priority")}: </div>
                      {!isPriorityClick ? (
                        <div className="sub-content" onClick={onPriorityClick}>
                          <button className="priority-button">{priority}</button>
                        </div>
                      ) : (
                        <select
                          className="priority-select"
                          value={priority}
                          onChange={(e) => onSelectPriority(e.target.value)}
                        >
                          {priorityList.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("work_effort")}: </div>
                      <div className="sub-content">
                        {workeffort} {t("hours")}
                      </div>
                    </div>
                    <div className="row mt-2 mb-4 justify-content-center">
                      <div className="col-5 text-center normal-offer-card">
                        <div>{t("normal")}</div>
                        <div className="price">
                          {hourlyrate_change * workeffort == 0
                            ? "FREE"
                            : `$${hourlyrate_change * workeffort}`}
                        </div>
                        <div className="horizontal-line"></div>
                        <div>
                          {t("due_date")}: {new Date(duedate).toLocaleDateString()}
                          <br />
                          +/- 3-5 days
                        </div>
                      </div>

                      <div className="col-1"></div>
                      <div className="col-5 text-center fast-offer-card">
                        <div>
                          <i className="bi bi-lightning-fill"></i> {t("fast")}
                        </div>
                        <div className="price">
                          {/* {fasthourlyrate * workeffort == 0
                      ? "FREE"
                      : `$${fasthourlyrate * workeffort}`} */}
                          {fasthourlyrate_change * workeffort == 0
                            ? "FREE"
                            : `$${fasthourlyrate_change * workeffort}`}
                        </div>
                        <div className="horizontal-line"></div>
                        <div>
                          {t("due_date")}:{" "}
                          {new Date(fastduedate).toLocaleDateString()}
                          <br />
                          100% guarantee
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <button
                        className="estimate-button"
                        onClick={() => setChangeofferShow(true)}
                      >
                        {t("change_offer")}
                      </button>
                      <div>{t("waiting_for_confirmation")}</div>
                    </div>
                  </>
                )
              ) : (
                <></>
              )}

              {status == STATUS.CONFIRMED ? (
                user.role == ROLE.CLIENT ? (
                  <>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("cost")}: </div>
                      <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                        })`}</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        {selectedoffer == OFFER.NORMAL
                          ? new Date(duedate).toLocaleDateString()
                          : new Date(fastduedate).toLocaleDateString()}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex mt-2 align-items-center">
                      <div className="sub-title">{t("priority")}: </div>
                      {!isPriorityClick ? (
                        <div className="sub-content" onClick={onPriorityClick}>
                          <button className="priority-button">{priority}</button>
                        </div>
                      ) : (
                        <select
                          className="priority-select"
                          value={priority}
                          onChange={(e) => onSelectPriority(e.target.value)}
                        >
                          {priorityList.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("work_effort")}: </div>
                      <div className="sub-content">
                        {workeffort} {t("hours")}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("mode")}: </div>
                      <div className="sub-content">{priority}</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        {selectedoffer == OFFER.NORMAL
                          ? new Date(duedate).toLocaleDateString()
                          : new Date(fastduedate).toLocaleDateString()}
                      </div>
                    </div>
                    {assignShow ? (
                      <div className="d-flex justify-content-end">
                        <button
                          className="assign-button"
                          onClick={(e) => {
                            getDeveloper();
                            setAssignShow(false);
                          }}
                        >
                          {t("assign")}
                        </button>
                      </div>
                    ) : (
                      <>
                        <div className="d-flex mt-2 align-items-center">
                          <div className="sub-title">{t("assign_to")}: </div>
                          <div className="sub-content">
                            <div>
                              <select
                                className="priority-select"
                                value={changeDeveloper}
                                onChange={(e) => setChangeDeveloper(e.target.value)}
                              >
                                {devList.map((item, index) => {
                                  return (
                                    <option key={index} value={index}>
                                      {item.username}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-2 justify-content-end align-items-center">
                          <button
                            className="estimate-cancel-button"
                            onClick={(e) => {
                              setAssignShow(true);
                            }}
                          >
                            {t("cancel")}
                          </button>
                          <button className="assign-button" onClick={onAssign}>
                            {t("complete")}
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )
              ) : (
                <></>
              )}
              {status == STATUS.ASSIGNED ? (
                user.role == ROLE.CLIENT ? (
                  <>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("cost")}: </div>
                      <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? t("hours") : t("hour")
                        })`}</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        {selectedoffer == OFFER.NORMAL
                          ? new Date(duedate).toLocaleDateString()
                          : new Date(fastduedate).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("assigned")}:</div>
                      <div className="sub-content">{developer.username}</div>
                      <div>
                        <img
                          className="assigned-developer-avatar"
                          src={developer.avatar}
                        />
                        {/* <Avatar
                    className="assigned-developer-avatar"
                    name={developer.username}
                    round={true}
                    size="24px"
                  /> */}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("in_progress")} </div>
                      <div className="sub-content d-flex align-items-center">
                        <div className="dot-typing"></div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <input
                        className="w-75"
                        type="range"
                        id="rangeinput"
                        value={percent}
                        onChange={() => { }}
                      />
                      <span className="progress-percentage">{percent}%</span>
                    </div>
                  </>
                ) : user.role == ROLE.DEV ? (
                  <>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("cost")}: </div>
                      <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? t("hours") : t("hour")
                        })`}</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        {selectedoffer == OFFER.NORMAL
                          ? new Date(duedate).toLocaleDateString()
                          : new Date(fastduedate).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("assigned")}:</div>
                      <div className="sub-content">{developer.username}</div>
                      <div>
                        <img
                          className="assigned-developer-avatar"
                          src={developer.avatar}
                        />
                        {/* <Avatar
                    className="assigned-developer-avatar"
                    name={developer.username}
                    round={true}
                    size="24px"
                  /> */}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("in_progress")} </div>
                      <div className="sub-content d-flex align-items-center">
                        <div className="dot-typing"></div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <input
                        className="w-100"
                        type="range"
                        id="rangeinput"
                        value={percent}
                        onChange={(e) => setPercent(e.target.value)}
                      />
                    </div>

                    <div className="d-flex justify-content-between  mt-2">
                      <button className="estimate-button" onClick={onChangeProgress}>
                        {t("submit")}
                        {` (${percent}%)`}
                      </button>
                      <button
                        className="estimate-button"
                        onClick={(e) => onComplete(false)}
                      >
                        {t("confirm_complete")}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("cost")}: </div>
                      <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                        })`}</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        {selectedoffer == OFFER.NORMAL
                          ? new Date(duedate).toLocaleDateString()
                          : new Date(fastduedate).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("assigned")}:</div>
                      <div className="sub-content">{developer.username}</div>
                      <div>
                        <img
                          className="assigned-developer-avatar"
                          src={developer.avatar}
                        />
                        {/* <Avatar
                    className="assigned-developer-avatar"
                    name={developer.username}
                    round={true}
                    size="24px"
                  /> */}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("in_progress")} </div>
                      <div className="sub-content d-flex align-items-center">
                        <div className="dot-typing"></div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <input
                        className="w-100"
                        type="range"
                        id="rangeinput"
                        value={percent}
                        onChange={(e) => setPercent(e.target.value)}
                      />
                    </div>

                    <div className="d-flex justify-content-between  mt-2">
                      <button className="estimate-button" onClick={onChangeProgress}>
                        {t("submit")}
                        {` (${percent}%)`}
                      </button>
                      <button
                        className="estimate-button"
                        onClick={(e) => onComplete(false)}
                      >
                        {t("confirm_complete")}
                      </button>
                    </div>
                  </>
                )
              ) : (
                <></>
              )}

              {status == STATUS.COMPLETED ? (
                user.role == ROLE.CLIENT ? (
                  <>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("cost")}: </div>
                      <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                        })`}</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        {selectedoffer == OFFER.NORMAL
                          ? new Date(duedate).toLocaleDateString()
                          : new Date(fastduedate).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("done")} </div>
                      <div className="sub-content d-flex align-items-center">
                        <div className="check-mark">
                          <i className="bi bi-check-circle-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <button className="estimate-button" onClick={(e) => onPay()}>
                        {t("confirm_pay")}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("cost")}: </div>
                      <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                        })`}</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        {selectedoffer == OFFER.NORMAL
                          ? new Date(duedate).toLocaleDateString()
                          : new Date(fastduedate).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("done")} </div>
                      <div className="sub-content d-flex align-items-center">
                        <div className="check-mark">
                          <i className="bi bi-check-circle-fill"></i>
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <></>
              )}
              {status == STATUS.PAID ? (
                user.role == ROLE.CLIENT ? (
                  <>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        {selectedoffer == OFFER.NORMAL
                          ? new Date(duedate).toLocaleDateString()
                          : new Date(fastduedate).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("done_paid")}</div>
                      <div className="sub-content d-flex align-items-center">
                        <div className="check-mark">
                          <i className="bi bi-check-circle-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("feedback")} </div>
                      <div className="sub-content">
                        {[...Array(5)].map((star, index) => {
                          index += 1;
                          return (
                            <button
                              key={index}
                              className={
                                index <= (hoverRating || rating)
                                  ? "star-button-on"
                                  : "star-button-off"
                              }
                              onClick={() => setRating(index)}
                              onMouseEnter={() => setHoverRating(index)}
                              onMouseLeave={() => setHoverRating(rating)}
                            >
                              <i className="bi bi-star-fill"></i>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="d-flex mt-2 justidy-content-between">
                      <div className="sub-title">{t("comment")} </div>
                      <div className="sub-content w-100">
                        <textarea
                          value={comment}
                          className="w-100 p-2"
                          rows="3"
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <button className="estimate-button" onClick={onSubmitRating}>
                        {t("submit")}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("cost")}: </div>
                      <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                        })`}</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("due_date")}: </div>
                      <div className="sub-content">
                        {selectedoffer == OFFER.NORMAL
                          ? new Date(duedate).toLocaleDateString()
                          : new Date(fastduedate).toLocaleDateString()}
                      </div>
                    </div>

                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("done_paid")}</div>
                      <div className="sub-content d-flex align-items-center">
                        <div className="check-mark">
                          <i className="bi bi-check-circle-fill"></i>
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <></>
              )}

              {status == STATUS.GETFEEDBACK || status == STATUS.ARCHIVED ? (
                <>
                  {status == STATUS.ARCHIVED && (
                    <div className="d-flex mt-2">
                      <div className="sub-title">{t("cost")}: </div>
                      <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                        })`}</div>
                    </div>
                  )}
                  <div className="d-flex mt-2">
                    <div className="sub-title">{t("due_date")}: </div>
                    <div className="sub-content">
                      {selectedoffer == OFFER.NORMAL
                        ? new Date(duedate).toLocaleDateString()
                        : new Date(fastduedate).toLocaleDateString()}
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="sub-title">{t("done_paid")}</div>
                    <div className="sub-content d-flex align-items-center">
                      <div className="check-mark">
                        <i className="bi bi-check-circle-fill"></i>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="sub-title">{t("feedback")} </div>
                    <div className="sub-content">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            key={index}
                            className={
                              index <= rating ? "star-button-on" : "star-button-off"
                            }
                          >
                            <i className="bi bi-star-fill"></i>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  {comment && (
                    <div className="d-flex mt-2 justidy-content-between">
                      <div className="sub-title">{t("comment")} </div>
                      <div className="sub-content w-100">{comment}</div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              {(user.role == ROLE.DEV || user.role == ROLE.ADMIN) &&
                (task.status == STATUS.CREATED ||
                  task.status == STATUS.ESTIMATED ||
                  task.status == STATUS.CONFIRMED) && (
                  <div className="d-flex mt-2 justify-content-end">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          {t("free_task_tooltip")}
                        </Tooltip>
                      }
                    >
                      <button className="assign-button" onClick={onMoveToProgress}>
                        {t("move_to_progress")}
                      </button>
                    </OverlayTrigger>
                  </div>
                )}
            </div>
            {
              <Modal
                show={modalShow}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <div className="p-3">
                  <Deposit modalShow={modalShow} setModalShow={setModalShow} setConfettiStatus={setConfettiStatus} />
                </div>
              </Modal>}
          </div>
      }
    </>
  );
});

export default Task;
