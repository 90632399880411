import React, { useState, useEffect, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { API_URL, CURRENCY, LANGUAGE } from "../../constants/constants";
import { SocketContext } from "../../context/socket";
import moment from "moment";
import { useTranslation } from "react-i18next";
import linkdinIcon from "../../assets/images/linkdin.svg"
import youtubeIcon from "../../assets/images/youtube.svg"
import { useDebouncedState } from "../../utils/debouncedState.js";
import ContactManager from "./ContactManager.js";
import { supportedLanguages } from "../../constants/languages.js";
import { MemoizedVideoMeeting } from "./VideoMeeting";
import { ROLE } from "../../constants/constants";
import AccessDataUser from "./AccessDataUser";
import api from "../../utils/axios";
import { toast } from "react-toastify";

let otherPersons = [];
let otherOnline = [];
let otherLastSeenTime = [];

export default function ChatBoxTitle() {
    const { t } = useTranslation();
    const currentTask = useSelector((state) => state.current);
    const messages = useSelector((state) => state.message)
    const [persons, setPersons] = useState([]);
    const [online, setOnline] = useState([]);
    const [lastSeenTime, setLastSeenTime] = useState([]);
    const socket = useContext(SocketContext);
    const [showContactManager, setShowContactManager] = useState(false);
    const [showVideoMeeting, setShowVideoMeeting] = useState(false);
    const [showAccessData, setShowAccessData] = useState(false);
    const [provideStatus, setProvideStatus] = useState();

    const [averageResponseTime, setAverageResponseTime] = useState(null);

    const userData = getUserFromLocalStorage();

    useEffect(() => {
        const fetchAverageResponseTime = async () => {
            try {
                await api.get(`${API_URL}/api/v1/task/average-response-time/${currentTask._id}`)
                    .then((res) => {
                        setAverageResponseTime(res.data.averageResponseTime);
                    })
            } catch (error) {
                // console.error('Error fetching average response time:', error);
            }
        };

        fetchAverageResponseTime();
    }, [currentTask._id]);

    const languageList = [
        {
            language: t("english"),
            slanguage: "EN",
            value: LANGUAGE.EN,
            code: "en",
        },
        {
            language: t("german"),
            slanguage: "DE",
            value: LANGUAGE.DE,
            code: "de",
        },
        {
            language: "Spanish",
            slanguage: "ES",
            value: LANGUAGE.ES,
            code: "es",
        },
    ];

    const getLanguageNameFromCode = () => {
        const clientlanguage = supportedLanguages?.filter((language) => language["code"] == currentTask?.client?.translatelanguage);
        return clientlanguage?.[0]?.["language"];
    };

    const languageNameFromCode = useMemo(() => getLanguageNameFromCode(), [currentTask?.client?.translatelanguage]);

    const handleRedirectLinkedinAndYoutube = (url) => window.open(url, "_blank");

    useEffect(() => {
        const user = getUserFromLocalStorage();
        const users = currentTask?.users;
        const tempPerson = users?.filter((item) => item._id != user._id);
        setPersons([...tempPerson]);
        otherPersons = [...tempPerson];
        let tempOnline = [];
        let tempLastSeenTime = [];

        for (let i = 0; i < tempPerson.length; i++) {
            tempOnline.push(true);
            tempLastSeenTime.push(new Date());
            socket.emit("is-online", tempPerson[i]._id);
        }

        // console.log('tempOnline', tempOnline);
        // console.log('tempLastSeenTime', tempLastSeenTime);

        otherOnline = [...tempOnline];
        otherLastSeenTime = [...tempLastSeenTime];

        setOnline([...tempOnline]);
        setLastSeenTime([...tempLastSeenTime]);
    }, [currentTask._id, currentTask.users]);

    // useEffect(() => {
    //   const user = getUserFromLocalStorage();
    //   socket.on("offline-received", (data) => {
    //     const { userId, lastseen } = data;

    //     console.log(`Offline-received ${userId}, ${lastseen}`);
    //     let tempOnline = [...online];
    //     let tempLastSeenTime = [...lastSeenTime];
    //     for (let i = 0; i < otherPersons.length; i++) {
    //       if (otherPersons[i]._id == userId) {
    //         tempOnline[i] = false;
    //         tempLastSeenTime[i] = lastseen;
    //       }
    //     }
    //     console.log(userId, tempOnline);
    //     setOnline([...tempOnline]);
    //     setLastSeenTime([...tempLastSeenTime]);
    //   });

    //   socket.on("online-received", (userId) => {
    //     let tempOnline = [...online];
    //     console.log(tempOnline);
    //     for (let i = 0; i < otherPersons.length; i++) {
    //       if (otherPersons[i]._id == userId) {
    //         tempOnline[i] = true;
    //       }
    //     }
    //     console.log(otherPersons);
    //     console.log(userId, tempOnline);
    //     setOnline([...tempOnline]);
    //   });

    //   return () => {
    //     socket.removeListener("offline-received");
    //     socket.removeListener("online-received");
    //   };
    // }, []);

    useEffect(() => {
        const user = getUserFromLocalStorage();
        socket.on("offline-received", (data) => {
            const { userId, lastseen } = data;

            // console.log(`Offline-received ${userId}, ${lastseen}`); // offline tai day
            let tempOnline = [...otherOnline];
            let tempLastSeenTime = [...otherLastSeenTime];
            for (let i = 0; i < otherPersons.length; i++) {
                if (otherPersons[i]._id == userId) {
                    tempOnline[i] = false;
                    tempLastSeenTime[i] = lastseen;
                }
            }
            // console.log(userId, tempOnline);
            // console.log('other person', otherPersons);
            // setOnline([...tempOnline]);
            // setOnline([false])
            setLastSeenTime([...tempLastSeenTime]);
            otherOnline = [...tempOnline];
            otherLastSeenTime = [...tempLastSeenTime];
        });

        socket.on("online-received", (userId) => {
            let tempOnline = [...otherOnline];
            for (let i = 0; i < otherPersons.length; i++) {
                if (otherPersons[i]._id == userId) {
                    tempOnline[i] = true;
                }
            }
            // setOnline([...tempOnline]);
            otherOnline = [...tempOnline];
        });

        return () => {
            socket.removeListener("offline-received");
            socket.removeListener("online-received");
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setOnline([...otherOnline]);
            setLastSeenTime([...otherLastSeenTime]);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const fetchAccessDataUser = async () => {
            await api.get(`${API_URL}/api/v1/chatbot/${currentTask._id}`)
                .then((res) => {
                    setProvideStatus(res.data.task.provideData);
                })
        }
        fetchAccessDataUser()
    }, [])

    const getLanguageFromCode = (code) => {
        const clientLanguage = languageList.filter(
            (language) => language.value == code
        );
        return clientLanguage[0].language;
    };

    const handleDataAccess = () => {
        switch (provideStatus) {
            case 0:
                socket.emit("request-provide-data", currentTask)
                break;
            case 1:
                setShowAccessData(true);
                break;
            case 2:
                toast.warning("User does not provide data");
            default:
                break;
        }
    }

    const handleRequestUrl = () => {
        socket.emit("request-url", currentTask)
    }

    return (
        <>
            <div className="chatbox-title d-flex align-items-center">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-start align-items-center">
                        {persons.map((person, index) => (
                            <div key={index} className="d-flex align-items-center me-5">
                                <div className="position-relative">
                                    <img src={person.avatar} className="team-avatar" />
                                    <div
                                        className={online[index] ? "online-status" : "offline-status"}
                                    ></div>
                                </div>
                                <div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <>
                                            <div className="d-flex align-items-center">
                                                <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                                                    {person.username}
                                                </div>

                                                {online[index] ? (
                                                    <></>
                                                ) : (
                                                    <div className="last-seen">
                                                        {lastSeenTime[index]
                                                            ? `(${t("last_seen")} ${moment(
                                                                lastSeenTime[index]
                                                            ).fromNow()})`
                                                            : ""}
                                                    </div>
                                                )}
                                            </div>
                                            {/* <div className="taskname">{currentTask.taskname}</div> */}
                                            {currentTask?.client?._id == person._id && (
                                                <div className="taskname d-flex">
                                                    {languageNameFromCode}
                                                    <div className="mx-1">{`(${currentTask.client.email})`}</div>
                                                    <div
                                                        className="mx-1">{currentTask.client && currentTask.client.country || ""}</div>
                                                </div>
                                            )}
                                        </>
                                        {person?.role === 0 && <div
                                            style={{ display: "flex", flexDirection: 'row', gap: 10, marginTop: 10 }}>
                                            <img
                                                style={{ cursor: "pointer" }}
                                                src={linkdinIcon}
                                                alt={""}
                                                onClick={() => handleRedirectLinkedinAndYoutube("https://www.linkedin.com/in/patrick-fuchshofer-0973271b6/")} />
                                            <img
                                                style={{ cursor: "pointer" }}
                                                src={youtubeIcon}
                                                alt={""}
                                                onClick={() => handleRedirectLinkedinAndYoutube("https://youtu.be/daslKHaX2LY")} />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {
                        userData.role === 0 && <p style={{ border: "1px solid #01666C", borderRadius: "7px", padding: "5px", color: "#01666C" }}>
                            {averageResponseTime}
                        </p>
                    }
                    <div>
                        {
                            userData.role !== ROLE.ADMIN && <button
                                className="video-meeting-button"
                                style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'center',

                                }}
                                onClick={() => setShowVideoMeeting(true)}
                            >
                                <i class="bi bi-camera-video-fill" style={{ fontSize: '20px', marginRight: '7px' }}></i>
                                <p>{t("schedule_a_video_call")}</p>
                            </button>
                        }
                        <div>
                            {
                                userData.role !== ROLE.CLIENT && <button
                                    style={{
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #00676c',
                                        color: '#00676c',
                                        borderRadius: '8px',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '17px',
                                        padding: '4px 10px',
                                        backgroundColor: 'transparent'
                                    }}
                                    onClick={handleDataAccess}
                                >
                                    <p>{provideStatus === 0 ? <div style={{ display: "flex", gap: "5px", justifyContent: "center", alignItems: "center" }}><i style={{ fontSize: "18px" }} class="bi bi-database-add"></i>request data</div> : provideStatus === 1 ? <div style={{ display: "flex", gap: "5px", justifyContent: "center", alignItems: "center" }}><i class="bi bi-database-fill"></i>access data</div> : <i class="bi bi-database-fill-slash"></i>}</p>
                                </button>
                            }
                            {/* {
                                userData.role !== ROLE.CLIENT && <button
                                    style={{
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #00676c',
                                        color: '#00676c',
                                        borderRadius: '8px',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '17px',
                                        padding: '4px 10px',
                                        backgroundColor: 'transparent'
                                    }}
                                    onClick={handleRequestUrl}
                                >
                                    <p>{provideStatus === 0 ? <div style={{ display: "flex", gap: "5px", justifyContent: "center", alignItems: "center" }}><i style={{ fontSize: "18px" }} class="bi bi-database-add"></i>request url</div> : provideStatus === 1 ? <div style={{ display: "flex", gap: "5px", justifyContent: "center", alignItems: "center" }}><i class="bi bi-database-fill"></i>access data</div> : <i class="bi bi-database-fill-slash"></i>}</p>
                                </button>
                            } */}
                        </div>
                    </div>
                    {/* <div>
          <button className="chatbox-title-right-button">
            <i className="bi bi-three-dots-vertical"></i>
          </button>
        </div> */}
                </div>
                {/* {
                    userData.role !== ROLE.ADMIN && <ContactManager
                        show={showContactManager}
                        setShow={setShowContactManager}
                    />
                } */}
                {
                    userData.role !== ROLE.ADMIN && <MemoizedVideoMeeting show={showVideoMeeting} setShow={setShowVideoMeeting} />
                }
                {
                    userData.role !== ROLE.CLIENT && provideStatus === 1 && <AccessDataUser show={showAccessData} setShow={setShowAccessData} />
                }
            </div>
        </>
    );
}
